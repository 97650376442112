$white:#ffffff;
$gray:#1F1F1F;
$light-green:#AFFF00;

$button-color:#ffdf88;
$border-color:#ffc76e;

.MintSection{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 10;
    height: 100vh;
    width: 100vw;
    margin: auto;
    text-align: center;
}

.mint-section-inner{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 550px;
    width: 450px;
    // background-color: $white;
    // background: linear-gradient(#191919, #191919)padding-box, linear-gradient(170deg, #05BA99 ,#14448D) border-box !important;    
    box-shadow: 0px 21px 163px 0px rgb(5 185 153 / 52%) inset;
    border-radius: 19px;
    border: 3px solid transparent !important;
    p{
        max-width: 60%;
    }
    max-width: 93vw;
}
.mint-section-inner-bottom{
    padding:0 50px 0px 50px;
    box-sizing: border-box;
    width: 100%;
   
}
.mint-input-div,.mint-amount-div{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 60px;
 
    input{
        height: 62px;
        width: 53%;
        border: 1px solid #FFF9E3;
        border-radius:16px;
        background-color: transparent;
        text-align: center;
        outline: none;
    }
   
}
.mint-amount-div{

    margin-top: 1.3rem;
}


.amount-button,.mint-button{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;
    width: 66px;
    background-color: transparent;
    background-image: linear-gradient(180deg, #01DA9C 0%, #1C0085 100%);    
    border:none;
    cursor: pointer;

    border: 1px solid #FFF9E3;
  
    border-radius: 16px;
    &:hover{
        background-image: none;
    }
}
.mint-button{
    width: 100%;
    margin-top: 1.3rem;
}
@media screen and (max-width:500px) {
    .mint-section-inner-bottom{
        padding:0 6vw 0px 6vw;
        box-sizing: border-box;
        width: 100%;
    }
}
@media screen and (max-width:430px) {
    .amount-button,.mint-button{
        height: 14.5vw;
        width: 18vw;
    }
    .mint-input-div,.mint-amount-div{
        height: 13.5vw;
        input{
            height: 100%;
        }
    }
    .mint-button{
        width: 100%;
    }
}
.connected-address{
    margin: 20px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top:0;
    right:0;
}
.not-listed-button{
    height: 50px;
    margin-top: 0.5rem;
    background: white;
    a{
        color:black !important;
    }
    
    width: 250px;
}