
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800;900&display=swap');

$light-green:#AFFF00;
$dark-gray:#181818;
$gray:#1E1E1E;
$light-gray:#4B4B4B;

.App {
  position: relative;
  background-color: black;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-tap-highlight-color: transparent;  
  background-image: url(./assets/images/bg-img.png);
  background-size: cover;
  background-position: start;
  animation: animation2 55s linear infinite;
}
@keyframes animation2 {
  0%{
    background-position-y: 0;
  }
  50%{
    background-position-y: 100%;
  }
  100%{
    background-position-y: 0;
  }
}
.app-second-section{
  position: relative;
  // padding-top:10px;
  width: 100vw;
  background:linear-gradient(180deg, #ecbd66 0%,#efb84e 72%, #d66437 100%);
  padding-bottom:50rem;
  @media screen and (max-width:1000px) {
    padding-top:0px;
  }
}
.cloud-holder{
  overflow: hidden;
  position: absolute;
  width: 100vw;
  height: 4992px;
  z-index: 0;
}
.app-clouds{
  position: absolute;
  bottom:0;
  top:unset;
  animation: cloudAni 85s linear infinite;
}
.app-clouds-initial{
  position: absolute;
  bottom:3000px;
  top:unset;
  animation: cloudAni2 30s linear infinite;
}


@keyframes cloudAni{
  0%{
    bottom:-300px;
  }
  100%{
    bottom:5000px;
  }
}
@keyframes cloudAni2{
  0%{
    bottom:3000px;
  }
  100%{
    bottom:5000px;
  }
}


///////

//  Font Size

///////


.font-xl{
  font-size:4rem;
}

.font-xlm{
  font-size:3.6rem;
}
.font-xls{
  font-size:3rem;
}
.font-l{
  font-size: 2rem;
}
.font-faq{
  font-size: 2rem;
  line-height: 30px;
}
.font-m{
  font-size: 1.7rem;
  line-height:25px;
}
.font-s{
  font-size: 1.5rem;
  line-height:28px;
}
.font-team-desc{
  font-size: 1.3rem;
  line-height:25px;
}
.font-team-bio{
  font-size: 1.01rem;
  line-height:24px;
}
.font-xs{
  font-size: 1.12rem;
  line-height:25px;
}
.font-xxs{
  font-size: 0.9rem;
  line-height: 19px;
}
@media screen and (max-width:675px) {
  .font-xs{
    font-size:1.05rem;
  }
  .font-faq{
    font-size:1.7rem;
  }
}
@media screen and (max-width:545px) {
  .font-faq{
    font-size:4.8vw;
  }
}

@media screen and (max-width:400px) {
  .font-xl{
    font-size:13vw;
  }
  .font-xls{
    font-size:11vw;
  }
  .font-l{
    font-size: 8vw;
  }
}



///////

//  Font Family

///////

.family-raleway{
  font-family: 'Raleway', sans-serif;
}


///////

//  Font Weight

///////


.weight-grand-med{  
  font-weight: 500;
}

.weight-regular{
  font-weight: 400;
}

.weight-600{
  font-weight: 500;
}

.weight-medium{
  font-weight: 600;
}

.weight-extra-bold{
  font-weight: 900;
}

.weight-bold{
  font-weight: 700;
}

///////

//  Text Color

///////

.text-white{
  color:#FFFFFF;
}
.text-green{
  color:$light-green;
}
.text-light-gray{
  color:#B2B2B2;
}
.text-black{
  color: black !important;
}
.text-red{
  color: #b63026 !important;
}
///////

//  Margins

///////


.mt-point5{
  margin-top:0.5rem;
}

.mt-1{
  margin-top:1rem;
}

.mt-2{
  margin-top:2rem;
}
.mt-4{
  margin-top:4rem;
}


.mb-point5{
  margin-bottom:0.6rem;
}
.mb-1{
  margin-bottom:1rem;
}
.mb-2{
  margin-bottom:2rem;
}
.mb-4{
  margin-bottom:4rem;
}

.ml-1{
  margin-left:1rem;
}
.ml-2{
  margin-left:2rem;
}
.ml-4{
  margin-left:4rem;
}

.mr-1{
  margin-right:1rem;
}
.mr-2{
  margin-right:2rem;
}
.mr-4{
  margin-right:4rem;
}

.no-line-height{
  line-height: 0px;
}


.text-center{
  text-align: center;
}

.text-caps{
  text-transform: capitalize;
}
.astronaut-img{
  position: absolute;
  top:0;
  bottom:0;
  right:0;
  margin: auto;
  animation: animation 1500ms ease-in infinite;
}
@keyframes animation {
  0%{
    top: 0px;
  }
  50%{
    top: 20px;
  }
  100%{
    top: 0px;
  }
}
@media screen and (max-width:1000px) {
  .astronaut-img{
    opacity: 0.7;
  }
}